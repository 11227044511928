import React, { useState } from 'react'
import { useAuth } from '../../context/auth'
// import { Link } from 'react-router-dom'
import {LoginForm} from '../../components/loginForm'

export const Register = () => {
  const [userName, setUserName] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const { RegisterUser } = useAuth()

  const handleLogin = (): void => {
    RegisterUser({ userName, password })
  }

  return (
    <div className='mb-5 mt-5'>
      <h2 className='fw-bold mb-2 text-uppercase form-title'>Pharmacy<br/>Innovations</h2>
      <p className='form-sub-title text-center'>Please enter your login and password!</p>
      <div className='my-5 mx-4'>
        <LoginForm
          setUserName={setUserName}
          setPassword={setPassword}
          handleLogin={handleLogin}
          btnTitle='Sign Up'
        />
        {/* <div className='mt-3'>
          <p className='mb-0 text-center form-footer-text'>
            Already registered?{' '}
            <Link to='/' className='text-primary fw-bold'>
              Sign in
            </Link>
          </p>
        </div> */}
      </div>
    </div>
  )
}
