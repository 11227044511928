import React, { useEffect, useState } from 'react'
import { api } from '../../lib/api'
import { useSearchParams } from 'react-router-dom'
import { Container, Spinner, Table } from 'react-bootstrap'
import CustomPagination from '../../components/Table/CustomPagination'
import { SearchForm } from './searchForm'
import { RowItem } from './row'
import { TableTitle } from '../../components/Table/tableTitle'
import { anyObjectProperty } from '../../@types/anyObjectProperty'
import { AxiosError } from 'axios'
import { useAdminPanel } from '../../context/adminPanel'
import { SubAlertErrorType } from '../../@types/authContext'
import { PageTitle } from '../../components/pageTitle'
import { useAuth } from '../../context/auth'

type SearchQueryParams = {
  blinkRxNumber: string
  status: string
  substatus: string
  requestTypes: string[] | string
  pharmacyNpi: string
  vendor: string
  patientName: string
  sortColumn: string
  sortOrder: string
  page: string
  pageSize: string
}

let initSearchParams: SearchQueryParams = {
  blinkRxNumber: '',
  status: '',
  substatus: '',
  requestTypes: '',
  pharmacyNpi: '',
  vendor: '',
  patientName: '',
  sortColumn: '',
  sortOrder: '',
  page: '1',
  pageSize: '10',
}

interface ResType extends SearchQueryParams {
  items: Array<anyObjectProperty>
  totalCount: number
}
export const Prescriptions = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchQueryParams, setSearchQueryParams] = useState(initSearchParams)
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState<anyObjectProperty>({ page: 1 })
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [currentData, setCurrentData] = useState<Array<anyObjectProperty>>([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const { setNewAlert } = useAdminPanel()
  const { user } = useAuth()

  useEffect(() => {
    if (user?.npi && user.npi.length > 0) {
      initSearchParams.pharmacyNpi = user.npi
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      getData()
    },
    // eslint-disable-next-line
    [currentPage, dataPerPage],
  )

  const getData = async () => {
    await getParamFromQuery().then(async (responseQueryParam) => {
      if (Number(responseQueryParam['pageSize']) !== dataPerPage) {
        setDataPerPage(Number(responseQueryParam['pageSize']))
        return null
      }
      if (Number(responseQueryParam['page']) !== Number(currentPage.page)) {
        setCurrentPage({ page: responseQueryParam['page'] })
        return null
      }

      try {
        const res: anyObjectProperty | ResType | any = await api.get(
          '/Admin/Prescriptions',
          {
            params: {
              ...responseQueryParam,
            },
            method: 'get',
          },
        )

        if (res.data.hasOwnProperty('status') && res.data?.status > 204) {
          setNewAlert({
            err: res.data as SubAlertErrorType,
            show: true,
          })
        } else {
          setTotalCount(res.data.totalCount)
          setLoading(false)
          setCurrentData([...res.data.items])
          // setCurrentPage({ page: responseQueryParam['page'] })
        }
      } catch (error) {
        setNewAlert({
          err: error as AxiosError,
          show: true,
        })
      }
    })
  }
  const getParamFromQuery = async () => {
    let urlData = { ...searchQueryParams }

    let props: anyObjectProperty = {}
    let populationArr = Object.entries(urlData)
    for (const [key, value] of populationArr) {
      const urlParam = searchParams.get(key) || value
      if (urlParam !== '') {
        props[key] = urlParam
      }
    }

    initSearchParams = { ...urlData, ...props }

    setSearchQueryParams({ ...urlData, ...props })
    setSearchParams({
      ...props,
    })

    return { ...urlData, ...props }
  }

  const updateSearchParam = async (sParam: anyObjectProperty) => {
    let props: anyObjectProperty = {}
    let populationArr = Object.entries(sParam)
    for (const [key, value] of populationArr) {
      if (value !== '') {
        props[key] = value
      }
    }
    setSearchParams({
      ...props,
    })
  }

  // Change page
  const paginate = async (pageNumber: number) => {
    const qParams = { ...searchQueryParams, page: pageNumber.toString() }
    setSearchQueryParams({ ...qParams })

    await updateSearchParam(qParams)
    setCurrentPage({ page: pageNumber })
  }

  const dataPerPageHandle = async (dataPerPage: number) => {
    const qParams = {
      ...searchQueryParams,
      pageSize: dataPerPage.toString(),
      page: '1',
    }

    setSearchQueryParams({ ...qParams })

    await updateSearchParam(qParams)

    setDataPerPage(dataPerPage)
    setCurrentPage({ page: 1 })
  }

  const searchRecords = async (event: React.BaseSyntheticEvent) => {
    event.preventDefault()
    const sRx = event.target.blinkRxNumber.value
    const sVal = event.target.status.value
    const subsVal = event.target.substatus.value
    // const rtVal = event.target.requestType.value
    let rtVal: string[] | string = ''
    const npiVal = event.target.pharmacyNpi.value
    const vendorVal = event.target.vendor.value
    const pNameVal = event.target.patientName.value || ''

    if (event.target.requestTypes && event.target.requestTypes.length > 1) {
      rtVal = []
      for (let i = 0; i < event.target.requestTypes.length; i++) {
        rtVal.push(event.target.requestTypes[i].value)
      }
      rtVal = rtVal.join(',')
    } else {
      rtVal = event.target.requestTypes.value
    }

    const sParam = {
      ...searchQueryParams,
      blinkRxNumber: sRx,
      status: sVal,
      substatus: subsVal,
      requestTypes: rtVal,
      pharmacyNpi: npiVal,
      vendor: vendorVal,
      patientName: pNameVal,
      pageSize: dataPerPage.toString(),
      page: '1',
    }

    setSearchQueryParams({
      ...sParam,
    })

    await updateSearchParam(sParam)

    setCurrentPage({ page: 1 })
  }

  return (
    <>
      <PageTitle>Prescriptions</PageTitle>
      <Container fluid className='p-sm-4 '>
        {loading ? (
          <Spinner animation='border' variant='primary' />
        ) : (
          <>
            <SearchForm
              searchHandle={searchRecords}
              formValue={searchQueryParams}
            />
            <Table
              striped={false}
              borderless
              hover
              responsive={true}
              className='bg-border-left'
            >
              <thead>
                <TableTitle
                  titleList={[
                    'External RX#',
                    'Patient',
                    'Created Date',
                    'Ready by',
                    'Request type',
                    'Modified Date',
                    'Status',
                    'SubStatus',
                    'PK RX#',
                    'Action',
                  ]}
                />
              </thead>
              <tbody>
                {currentData?.map((item: { [key: string]: any }, index) => (
                  <RowItem item={item} key={item.id + '-row'} />
                ))}
              </tbody>
              <tbody></tbody>
            </Table>
            <CustomPagination
              dataPerPage={dataPerPage}
              dataPerPageHandle={dataPerPageHandle}
              totalData={totalCount}
              paginate={paginate}
              currentPage={Number(currentPage.page)}
            />
          </>
        )}
      </Container>
    </>
  )
}
