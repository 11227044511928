export const getRandomKey = () => Math.floor(Math.random() * 10000000) + 1

export const formatDate = (d: string, toISO: boolean = false) => {

  if (d === null || d.length === 0) {
    return d;
  }
  const dateList = d.split(',');

  let dateRes: string[] = [];

  for (let index = 0; index < dateList.length; index++) {
    let date: any = new Date(dateList[index]);

    if (!dateList[index].includes('T')) {
      const modifyDate: string = dateList[index]+'T00:00:00';
      date = new Date(modifyDate);
    }

    if (!isNaN(date) === false) {
      dateRes.push(dateList[index]);
    }

    if (toISO) {
      let hh = date.getHours();
      let mm = date.getMinutes();
      let ss = date.getSeconds();
      // If you were building a timestamp instead of a duration, you would uncomment the following line to get 12-hour (not 24) time
      // if (hh > 12) {hh = hh % 12;}
      // These lines ensure you have two-digits
      if (hh < 10) {hh = "0"+hh;}
      if (mm < 10) {mm = "0"+mm;}
      if (ss < 10) {ss = "0"+ss;}

      const times = hh+":"+mm+":"+ss;
      const dateString = date.toDateString() + ' ' + times;
      dateRes.push(dateString);
      // dateRes.push(date.toTimeString());
    } else {
      dateRes.push(date.toDateString());
    }

  }

  return dateRes.join(', ');

}

export const toSentenceCase = (camelCase: string) => {
  if (camelCase) {
    const result = camelCase.replace(/([A-Z])/g, ' $1')
    // return result[0].toUpperCase() + result.substring(1).toLowerCase()
    return result[0].toUpperCase() + result.substring(1)
  }
  return ''
}
